<template>
  <div>
    <h3>{{ $t('general.documents') }}</h3>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('general.general_information') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="18" />
          </v-card-text>
        </v-card>
        <v-card class="mt-1">
          <v-card-title>
            {{ $t('general.exam_location') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="22" />
          </v-card-text>
        </v-card>
        <v-card class="mt-1">
          <v-card-title>
            {{ $t('general.dates') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="23" />
          </v-card-text>
        </v-card>
        <v-card class="mt-1">
          <v-card-title>
            {{ $t('general.evaluation_grid_in_writing') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="24" />
          </v-card-text>
        </v-card>
        <v-card class="mt-1">
          <v-card-title>
            {{ $t('general.evaluation_grid_oral') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="25" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('general.written_exam') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="19" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('general.oral_exam') }}
          </v-card-title>
          <v-card-text>
            <DocumentList :i_DocumentType="20" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import DocumentList from '../../../components/general/DocumentList.vue'
  export default {
    name: 'ExpertDocuments',
    components: {
      DocumentList,
    },
    data: function () {
      return {
      }
    },
    created () {
    },
    mounted () {},
    methods: {},
  }
</script>

<style>
</style>